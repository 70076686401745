<template>
    <div>
        <PageHeader :title="'UPLOADS'" :items="breadcrumb" />

        <h2>Planilha CCEE Upload:</h2>

        <b-spinner v-if="loading" text-align="center" variant="success" label="Spinning" class="ml-2 mt-3" />

        <FormFile @file-uploaded="uploadFileCCEE" :mes="true"  v-if="hasPermission('backoffice_edit')"></FormFile>

        <h2>Planilha Backoffice Upload:</h2>

        <FormFile @file-uploaded="uploadFileBAckoffice" :mes="true"  v-if="hasPermission('backoffice_edit')"></FormFile>
    </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import FormFile from '@/components/form-file';
import { uploadPlanilhaCCEE, uploadPlanilhaBackoffice } from './PlanilhaUpload.service';
import { hasPermission } from '@/features/auth/auth.acl';

export default {
    data() {
        return {
            loading: false,
            breadcrumb: [
                {
                    text: 'Backoffice',
                    href: '#'
                },
                {
                    text: 'Uploads',
                    active: true
                }
            ]
        }
    },
    components: {
        PageHeader, FormFile
    },
    methods: {
        async uploadFileCCEE(data) {
            this.loading = true;

            await uploadPlanilhaCCEE(data)
                .then((response) => {
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        async uploadFileBAckoffice(data) {
            this.loading = true;

            await uploadPlanilhaBackoffice(data)
                .then((response) => {
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        hasPermission(requiredAcl) {
            return hasPermission(requiredAcl);
        }
    }
}
</script>