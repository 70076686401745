import user from '@/features/auth/auth.store';

export function hasPermission(requiredAcl) {
    let permissions = user.state.acl;

    return (
        requiredAcl !== undefined &&
        (permissions.indexOf(requiredAcl) !== -1)
    );

}