import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';
import { Base64 } from 'js-base64';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');


const CONFIG = new Config();

export const uploadPlanilhaCCEE = async (data) => {
  var axios = require('axios');

  const formData = new FormData(); formData.append('file', data.file);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/contratoccee/uploadPlanilha`,
    headers: {
      Bearer: auth.token,
      'Content-Type': 'multipart/form-data'
    },
    params: {
      mesRef: data.mesRef
    },
    data: formData
  };

  return await axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};

export const uploadPlanilhaBackoffice = async (data) => {
  var axios = require('axios');

  const formData = new FormData(); formData.append('file', data.file);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRABACKOFFICE}/backoffice/comercializadora/boletas/uploadPlanilha`,
    headers: {
      Bearer: auth.token,
      'Content-Type': 'multipart/form-data'
    },
    params: {
      mesRef: data.mesRef
    },
    data: formData
  };


  return await axios(config)
    .then(function (response) {
      if (response.data.status) {
        Vue.$toast.success(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      } else {
        Vue.$toast.error(response.data.msg, {
          position: 'top-right',
          duration: 5000
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      Vue.$toast.error("Erro", {
        position: 'top-right',
        duration: 5000
      });

      return null;
    });
};